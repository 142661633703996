<!-- 考试首页 -->
<style lang="scss" scoped>
@include pageFoot;
.page-head {
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba($color: #8b8b8b, $alpha: 0.12);
    padding-top: 20px;
    .single-search {
        max-width: 1200px;
        @include flexBox;

        // .search-ipt {
        //     flex-grow: 1;
        //     height: 60px;
        //     border-radius: 10px;
        //     background: #fff;
        //     @include flexBox;
            ::v-deep {
                .el-input {
                    &__inner {
                        height: 40px;
                        line-height: 40px;
                        background: #fff;
                        font-size: 16px;

                        &::placeholder {
                        color: #b5b5b5;
                        }

                        &:focus {
                        border-color: transparent;
                        }
                    }

                    &__prefix {
                        left: 32px;

                        .iconfont {
                        font-size: 24px;
                        }
                    }
                }
            }
            // 日期筛选
            .grade-select{
                min-width: 140px;
                line-height: 40px;
                border-right: 1px solid #EEEEEE;
                flex-shrink: 0;
                        font-size: 14px;
                ::v-deep {
                    .el-input__prefix{
                        left: 0;
                    }
                    .el-date-editor.el-input__inner .el-range-separator  {
                        line-height: 40px;
                    }
                    .el-date-editor.el-input__inner .el-range-input{
                        background-color: #FFFFFF;
                    }
                    .el-input__inner{
                        padding: 0 0 0 20px;
                        font-size: 14px !important;
                        &::before{
                            left: 115px;
                        }
                    }
                    .el-range-input{
                        padding: 0 10px;
                        text-align: left;
                    }
                } 
                
            }
        // }
        .el-button {
        flex-shrink: 0;
        margin-left: 22px;
        }
    }
}
*{
	box-sizing: border-box;
}
.pagetitle{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
}
.gradeList{
    scroll-snap-type: x mandatory;
    height: 140px;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 10px;
    position: relative;
   .item {
        width: 170px;
        background-color: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(37, 84, 255, 0.1);
        margin-right: 20px;
        border-radius: 10px;
        text-align: center;
        scroll-snap-align: start;
        flex-shrink: 0;
        padding-top: 14px;
        &:last-child{
            margin-right: 0;
        }
        .course{
            font-size: 18px;
            font-weight: bold;
        }
        .number{
            font-size: 20px;
            color: #FF7C12;
            margin-top: 15px;
        }
        .compare{
            margin-top: 14px;
            height: 26px;
            font-size: 15px;
            color: #888888;
            line-height: 26px;
            .plus{
                color: #FF7C12;
            }
            .minus{
                color: #1DBB62;
            }
        }
   }
}

.examineList{
    display: inline-block;
    height: calc(100vh - 500px);
    padding-bottom: 20px;
    overflow: auto;
    width: 100%;
    position: relative;
    .item{
        width: 355px;
        height: 145px;
        padding: 40px 15px 0 30px;
        float: left;
        margin-right: 20px;
        background-color: #FFFFFF;
        border-radius: 10px;
        margin-top: 20px;
        position: relative;
        &:hover{
            background-color: #F6F7FC;
            cursor: pointer;
        }
        .state{
            position: absolute;
            left: 0;
            top: 0;
            width: 71px;
            height: 28px;
            border-radius: 0px 0px 29px 0px;
            padding-left: 8px;
            line-height: 28px;
            font-size: 14px;
            color: #FFFFFF;
            &.type1{
                background: #EBEBEB;
                color: #888888;
            }
            &.type2{
                background:#FF7C12;
            }
            &.type3{
                background: #01A1FD;
            }
            &.type4{
                background: #eee5ff;
                color: #01A1FD;
            }
        }
        .nameDate{
            .name{
                width: 160px;
                height: 24px;
                font-size: 16px;
                font-weight: bold;
                color: #3D3D3D;
                line-height: 24px;
            }
            .genre{
                .tag{
                    float: left;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    font-size: 14px;
                    height: 26px;
                    border-radius: 50px;
                    &.type1{
                        background: rgba(1,161,253,0.1);
                        border: 1px solid #01A1FD;
                        color: #01A1FD;
                    }
                    &.type2{
                        background: rgba(29,187,98,0.1);
                        border: 1px solid #1DBB62;
                        color: #1DBB62;
                    }
                    &.type3{
                        background: rgba(255,124,18,0.1);
                        border: 1px solid #FF7C12;
                        color: #FF7C12;
                    }
                    &.type4{
                        background: #F3F5F8;
                        border: 2px solid #AAAAAA;
                        color: #AAAAAA;
                    }
                }
            }
        }
        .p{
            font-size: 12px;
            font-weight: 400;
            color: #888888;
            line-height: 20px;
            margin-top: 5px;
        }
        .date{
            font-size: 14px;
            color: #222222;
            line-height: 20px;
            margin-top: 10px;
            .iconfont{
                font-size: 18px;
                margin-right: 6px;
            }
            &.finish{
                color: #888888;
                
            }
        } 
    }   
}
</style>

<template>
    <div class="index">
        <div class="pagetitle">
            考试情况
        </div>
        <div class="gradeList">
            <div class="item" v-for="(item,index) in gradeArray" :key="index">
                <div class="course">{{item.sysub_name}}</div>
                <div class="number flex_content_center">
                    {{item.score}} <div class="txt">分</div>
                </div>
                <div class="compare flex_content_center">
                    环比上次 
                    <div class="minus" v-if="sign(item.yoy)">{{item.yoy}}%</div>
                    <div class="plus" v-else>{{item.yoy}}%</div>
                </div>
            </div>
            
            <div class="no-data--empty" v-if="$isEmpty(gradeArray)">
                <img src="@assets/images/no-data3.png" alt="" style="width:120px;">
                <p>暂无考试数据</p>
            </div>
        </div>
        <div class="page-head">
            <div class="single-search">
                <div class="grade-select flex_bet_align" style="width:280px;">
                    <el-date-picker v-model="startData"  type="daterange" range-separator="至" start-placeholder="开始日期"  end-placeholder="结束日期" @change="searchData"> </el-date-picker>
                </div>
                <div class="grade-select flex_bet_align" style="width:150px;">       
                    <el-select v-model="sysub_id" filterable placeholder="选择科目">
                        <el-option v-for="item in subjectArray" :key="item.sysub_id" :label="item.sysub_name" :value="item.sysub_id"></el-option>
                    </el-select>
                </div>
                <el-input v-model.trim="keyword" maxlength="100" placeholder="搜索试卷名称" clearable @clear="searchData" style="width:210px;">
                    <span slot="prefix" class="iconfont">&#xe61c;</span>
                </el-input>
                <el-button type="custom_primary" size="medium" @click="searchData">搜索</el-button>
                <el-button size="medium" @click="reset">重置</el-button>
            </div>
        </div>
        <div class="examineList">
            <div class="item" @click="goPaper(item)" v-for="(item,index) in list" :key="index">
                <div class="state type1" v-if="item.stexa_status==30">已结束</div>
                <div class="state type2" v-if="item.stexa_status==20">进行中</div>
                <div class="state type3" v-if="item.stexa_status==10">未开始</div>
                <div class="state type4" v-if="item.stexa_status==40">已批阅</div>
                <div class="nameDate flex_bet_align">
                    <div class="flex_align">
                        <div class="name clamp1">{{item.tepap_title}}</div>
                        <div class="genre">
                            <div class="tag" :class="item.stexa_type==10?'type1':'type3'">{{item.stexa_type==10?'线上':'线下'}}</div>
                            <div class="tag type2" v-if="item.stexa_finishstatus==30">已提交</div>
                            <div class="tag type4" v-if="item.stexa_finishstatus==20">未提交</div>
                        </div>
                    </div>
                </div>
                <div class="p clamp1">{{item.tepap_rule}}</div>
                <div class="date flex_align fw-b" :class="item.stexa_status==10?'finish':''"> 
                    <div class="iconfont">&#xe685;</div>
                    {{ item.stexa_starttime | formatTime("YYYY-MM-DD HH:mm") }} ~ {{ item.stexa_endtime | formatTime("HH:mm") }}
                </div>
            </div>
            <div class="no-data--empty" v-if="$isEmpty(list)">
                <img src="@assets/images/no-data3.png" alt="">
                <p>暂无考试数据哦~</p>
            </div>
        </div>
        <!-- 分页控件 -->
        <div class="custom-foot">
            <customPagination :current='tablePage.pageIndex' :total="tablePage.total" :size="tablePage.pageSize" @pageChange="flippingPage" />
        </div>
    </div>
</template>

<script>
import { stuexamination_list } from "@api/home"
import { $getSubject } from "@api/common"
import customPagination from "@comp/customPagination"
export default {
    name: 'examIndex',
    components: { customPagination },
    data() {
        return {
            //成绩分数列表
            gradeArray:[],
            //搜索key
            keyword:"",
            //考试列表
            list:[],
            //科目信息
            subjectArray:[],
            // 筛选日期
            startData:[],
            //选中的id列表
            sysub_id:"",
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                pageSize: 12,
                total: 0
            },
        }
    },
    created() {
        this.getSubject();
        this.getData();
    },
    methods: {
        //获取科目
        async getSubject(){
            let { data} = await  $getSubject();
            this.subjectArray=data
        },
        /** 获取公开课列表数据 */
        async getData() {
            let params = {
                pagesize:this.tablePage.pageSize,
                pageindex:this.tablePage.pageIndex,
             }
            if(this.startData.length>0){
                params.stexa_starttime=this.startData[0].getTime()/1000
                params.stexa_endtime=this.startData[1].getTime()/1000
            }
            if(this.keyword.length>0){
                params.tepap_title=this.keyword
            }
            if(this.sysub_id){
                params.sysub_id=this.sysub_id
            }
            let { data: res } = await stuexamination_list(params);
            this.tablePage.total = res.allcount;
            this.list = res.data;
            if(res.subject_data){
                this.gradeArray=res.subject_data
            }
            this.$forceUpdate();
        },
        /** 搜索 */
        searchData() {
            this.tablePage.pageIndex=1
            this.list = [];
            this.getData();
        },
        // 重置
        reset(){
            this.list = [];
            this.sysub_id = [];
            this.startData=[]
            this.tablePage.pageIndex=1
            this.keyword=""
            this.getData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        
        //是否是正数
        sign(text){
            if(text){
                return text.includes("-") 
            }
        },
        
        // 点击试题跳转
        goPaper(item){
            // 10=未完成,20=进行中,30=已完成
            if(item.stexa_finishstatus!=30){
                this.$router.push({
                    name:"EXAM_INATION_PAPER",
                    params: {
                    id:item.stexa_id
                    }
                })
            }else{
                this.$router.push({
                    name:"EXAM_INATION_FINISH",
                    params: {
                        id:item.stexa_id
                    }
                })
                
            }
        },
    },
}
</script>